import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import showdown from "showdown";

import Layout from "../components/layout";
import Container from "../components/container";
import Segments from "../components/segments";

export default ({ data }) => {
  const converter = new showdown.Converter();

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Somos uma empresa plural.{''}
        Juntamos todas as frentes, arquitetura, design de interiores, móveis,{' '}
        incorporações, feiras e eventos para entregar soluções completas para espaços diversos."
        />
        <meta
          property="og:description"
          content="Somos uma empresa plural.{''}
        Juntamos todas as frentes, arquitetura, design de interiores, móveis,{' '}
        incorporações, feiras e eventos para entregar soluções completas para espaços diversos."
        />
        <meta name="image" content="/img/voga1.jpg" />
        <meta property="og:image" content="/img/voga1.jpg" />
        <title>Sobre - Grupo Vinte</title>
        <link rel="canonical" href="https://grupovinte.com.br/sobre/" />
        <html lang="pt-br" />
      </Helmet>
      <main style={{ minHeight: "72vh" }}>
        <Container>
          <h1>Quem Somos</h1>

          <div
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(
                data.markdownRemark.frontmatter.description
              )
            }}
          />

          <h2 style={{ marginBottom: 0, marginTop: "60px" }}>
            Conheça as nossas frentes:
          </h2>
          <Segments />
        </Container>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query HomePageQuery {
    markdownRemark(frontmatter: { page: { eq: "sobre" } }) {
      frontmatter {
        title
        description
      }
    }
  }
`;
